

@media screen and (min-width: 600px) {
    .video-container{
        margin: 0;
        transform: translate(50%, 30%);
    }

    .overlay{
        transform: translate(0%, 0%);
    }
}

@media screen and (max-width: 500px) {
    .video-container{
        transform: translate(0%, 50%);
        z-index: 80 !important;
    }

    .overlay{
        transform: translate(00%, 0%);
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}